import lottie from 'lottie-web/build/player/lottie_light';

window.addEventListener('load', (event) => {
	const animation = lottie.loadAnimation({
		container: document.getElementById('logo-animated'),
		path: '/images/logo-animated.json',
		renderer: 'svg',
		loop: false,
		autoplay: true,
	});
});
